import { ModalContext } from 'modules/contexts'
import { use, useState } from 'react'

export const ModalProvider = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return <ModalContext value={{ modalIsOpen, setModalIsOpen }}>{children}</ModalContext>
}

export const useModalIsOpen = () => {
  const { modalIsOpen, setModalIsOpen } = use(ModalContext)
  return [modalIsOpen, setModalIsOpen]
}
