import { animated, useTransition } from '@react-spring/web'
import * as D from 'modules/document'
import * as _ from 'modules/util'
import * as R from 'ramda'
import { useMemo, useState } from 'react'

const TransitionList = ({
  as: Wrapper = 'ul',
  className,
  config = { tension: 125, friction: 20 },
  enter: enterIn = {},
  from,
  itemAs: Item = DefaultItem,
  items,
  itemWrapper: ItemWrapper = animated.li,
  leave,
  setItems,
  timeout,
}) => {
  const [mountedItemCount, setMountedItemCount] = useState(0)
  const elementMap = useMemo(() => new WeakMap(), [])
  const cancelMap = useMemo(() => new WeakMap(), [])

  const removeItem = item => setItems(R.reject(R.whereEq({ id: item.id })))

  const transitions = useTransition(items, {
    keys: item => item.id,
    from: { ...from, life: 1 },
    enter: item => async (next, cancel) => {
      setMountedItemCount(R.inc)
      cancelMap.set(item, cancel)
      const enter = R.map(R.when(_.isFunction, _.applyTo(elementMap.get(item))), enterIn)
      const startTime = Date.now()
      await new Promise(window.requestAnimationFrame) // waits for tab to be visible

      const itemTimeout = item.timeout || timeout

      if (itemTimeout) {
        if (Date.now() - startTime < itemTimeout) {
          setTimeout(() => {
            if (cancelMap.has(item)) {
              cancelMap.get(item)()
              removeItem(item)
            }
          }, itemTimeout)
          await next(enter)
          await next({ life: 0 })
        }
      } else {
        await next(enter)
      }
    },
    leave: () => async next => {
      if (D.tabIsHidden()) {
        setMountedItemCount(R.dec)
        return
      }
      await next(leave)
      setMountedItemCount(R.dec)
    },
    onRest: (result, ctrl, item) => {
      if (item.timeout || timeout) removeItem(item)
    },
    config: (item, index, phase) => key =>
      _.cond(
        [phase === 'enter' && key === 'life', { duration: item.timeout || timeout }],
        [key === 'opacity', { ...(item.config || config), clamp: true }],
        [item.config || config]
      ),
  })

  const remove = (item, life) => () => {
    if (cancelMap.has(item) && life.get() !== 0) {
      cancelMap.get(item)()
      cancelMap.delete(item)
    }
    life.set(0)
    if (!timeout && !item.timeout) removeItem(item)
  }

  if (mountedItemCount === 0 && !items.length) return null

  return (
    <Wrapper className={className}>
      {transitions(({ life, ...style }, item) => (
        <ItemWrapper style={style}>
          <Item
            ref={el => el && elementMap.set(item, el)}
            isLeaving={life.get() === 0}
            remove={remove(item, life)}
            {...item}
          />
        </ItemWrapper>
      ))}
    </Wrapper>
  )
}

const DefaultItem = ({ isLeaving: _isLeaving, ref, remove: _remove, ...props }) => (
  <div ref={ref} {...props} />
)

export default TransitionList
